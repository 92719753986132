<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import '@/scss/app.scss'

export default {
  computed: {
    layout() {
      return `layout-${this.$route.meta.layout || 'default'}`
    }
  },
  created() {
    var scripts = [
      "https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js",
        "https://code.jquery.com/jquery-3.5.1.slim.min.js",
        "https://www.podshealth.com/polyfills.1e2da32b62f442e345ae.js",
        "https://www.podshealth.com/scripts.5448be4ca5189e589951.js",
        "https://www.podshealth.com/main.32d3b1c7f6fa32e7ec59.js",
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/16.0.1/js/utils.js",
    //  "js/local.js"
    ];
    scripts.forEach(script => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);

    });
  }
}
</script>

<style>
@import '/assets/css/main.css';
</style>
