<template>
  <div class="default-page-wrapper">
    <body class="mat-typography">
    <default-nav />
    <slot />
    <default-footer />
    </body>

  </div>
</template>

<script>
import DefaultNav from './DefaultNav.vue'
import DefaultFooter from './DefaultFooter.vue'

export default {
  components: {
    DefaultNav,
    DefaultFooter
  }
}
</script>

<style>




</style>
