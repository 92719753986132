<template>


    <div _ngcontent-xhv-c60="" class="main">

        <nav _ngcontent-xhv-c58="" fxlayout="column" class="navbar navbar-expand-lg navbar-light" style="flex-direction: column; box-sizing: border-box; display: flex;">
          <div _ngcontent-xhv-c58="" class="container-fluid list">
            <a _ngcontent-xhv-c58="" routerlink="" class="navbar-brand nav-link active" href="/"><img _ngcontent-xhv-c58="" src="images/POD_GIF.gif" alt="" class="logo" /></a>
            <span _ngcontent-xhv-c58="" class="mob-title"> Home </span>
            <button _ngcontent-xhv-c58="" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler">
              <span _ngcontent-xhv-c58="" class="navbar-toggler-icon"></span>
            </button>
            <div _ngcontent-xhv-c58="" id="navbarNav" class="collapse navbar-collapse">
              <ul _ngcontent-xhv-c58="" class="navbar-nav">
                <li _ngcontent-xhv-c58="" class="nav-item">
                  <a _ngcontent-xhv-c58="" aria-current="page" data-toggle="collapse" data-target=".navbar-collapse" routerlink="about" class="nav-link" href="/about">About Us</a>
                </li>
                <li _ngcontent-xhv-c58="" class="nav-item"><a _ngcontent-xhv-c58="" data-toggle="collapse" data-target=".navbar-collapse" routerlink="products" class="nav-link" href="/products">Products</a></li>
                <li _ngcontent-xhv-c58="" class="nav-item">
                  <a _ngcontent-xhv-c58="" data-toggle="collapse" data-target=".navbar-collapse" routerlink="consultationRegistration" class="nav-link" href="/consultationRegistration">Consult Services</a>
                </li>
                <li _ngcontent-xhv-c58="" class="nav-item"><a _ngcontent-xhv-c58="" data-toggle="collapse" data-target=".navbar-collapse" routerlink="blogs" class="nav-link" href="/blogs">Blogs</a></li>
                <li _ngcontent-xhv-c58="" class="nav-item"><a _ngcontent-xhv-c58="" routerlink="philanthropy" class="nav-link" href="/philanthropy">Philanthropy</a></li>
                <li _ngcontent-xhv-c58="" class="nav-item"><a _ngcontent-xhv-c58="" data-toggle="collapse" data-target=".navbar-collapse" routerlink="contact" class="nav-link" href="/contact">Contact Us</a></li>
                <li _ngcontent-xhv-c58="" class="nav-item"><a _ngcontent-xhv-c58="" data-toggle="collapse" data-target=".navbar-collapse" routerlink="help" class="nav-link" href="/help">FAQs</a></li>
                <li _ngcontent-xhv-c58="" class="nav-item">
                  <a _ngcontent-xhv-c58="" data-toggle="collapse" data-target=".navbar-collapse" routerlink="newproducts" class="nav-link" style="color: red;" href="/newproducts">Pod Product Portfolio</a>
                </li>
              </ul>
            </div>
          </div>
          <!---->
        </nav>



    </div>
    <!----><!---->


</template>

<script>
export default {
  data() {
    return {
      navbarExpanded: false
    }
  },
  methods: {
    toggleNavbar() {

    }
  }
}
</script>
